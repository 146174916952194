import request from './../request.js'

export function logList(params){
    return request({
        url: 'card/batchrechargelog/list.do?',
        method: 'post',
        data:params
    })
}
export function exportRecord(params){
    return request({
        url: 'card/batchrechargelog/exportExcelSync.do?',
        method: 'post',
        data:params
    })
}

export function getSetMealByCategory(params){
    return request({
        url: 'meal/setmeal/getSetMealByCategory.do?',
        method: 'post',
        data:params
    })
}

export function batchImportCardPackage(params){
    return request({
        url: 'card/batchImportCardPackageSync.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//viewFail.vue
export function batchrechargefail(params){
    return request({
        url: 'card/batchrechargefail/list.do?',
        method: 'post',
        data:params
    })
}


//agentBatchRechargeLog.vue
export function agentLogList(params){
    return request({
        url: 'agentbatchrechargelog/grid.do?',
        method: 'post',
        data:params
    })
}
export function getAgentPackageAll(params){
    return request({
        url: 'meal/setmeal/getAgentPackageAll.do?',
        method: 'post',
        data:params
    })
}